import React from "react"
import { Event, SEO, Layout } from "../components"
import { SUB_NAV_EVENTS_LINKS } from "../utils/links"

export default function EventTemplate({ pageContext }) {
  return (
    <Layout showSubMenu={true} currentLink="Eventos" links={SUB_NAV_EVENTS_LINKS}>
      <SEO title={`Evento - ${pageContext.title}`} />
      <Event {...pageContext} />
    </Layout>
  )
}
